import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }

    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: rgb(136 157 191);
        overflow-x: hidden;
    }
    #root{
        background:#f8f8f8;
        margin:0;
    }
    img[src="/img/svg/logo_en.svg"]{
        width:200px;
    }
    @media(min-width:1280px){
        #root{
            margin:0 calc((100vw - 1290px) / 2);
        }
        img[src="/img/svg/logo_en.svg"]{
            width:500px;
        }
    }
    @media(min-width:1024px){
        .ant-card-bordered.card{
            width:calc(100% / 3);
            border: none;
            background: transparent;
        }
        .ant-col.special{
            max-width: calc(100% - 320px);
            width: calc(100% - 320px);
            flex:unset;
        }
        .ant-col.special > div{
            width:100%;
            max-width:100%;
        }
    }
    @media(max-width:767px){
        #intro.special{
            justify-content: center;
        }
        .ant-col.special > div{
            width:100%;
            max-width:100%;
            margin-top: 20px;
        }
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color:  #00a5ab;
        font-size: 46px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }
    .donations-title{
        font-size:30px;
    }
    p {
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
